import React from 'react';
import { graphql } from 'gatsby';

import {
  ContentfulAdvice,
  ContentfulAdvicesSection,
  ContentfulCookieBanner,
  ContentfulSettings,
  Site,
  ContentfulAdviceConnection,
} from '../generated/gatsby.types';
import Layout from '../components/layout';
import extractShortDescription from '../transforms/ShortDescription';
import transformProductsServicesSection from '../transforms/ProductsServices';
import transformProductCategoriesSection from '../transforms/ProductCategories';
import extractFileInforamtion from '../transforms/File';
import richTextRawToJson from '../utils/contentful-rich-text-raw-to-json';
import { AdvicePage } from '../ui-lib/components';

type AdviceQueryType = {
  contentfulAdvice: ContentfulAdvice;
  contentfulAdvicesSection: ContentfulAdvicesSection;
  contentfulCookieBanner: ContentfulCookieBanner;
  site: Site;
  contentfulSettings: ContentfulSettings;
  translatedSlugs: ContentfulAdviceConnection;
};

type PageContextType = {
  id: number;
  node_locale: string;
};

type AdviceProps = {
  data: AdviceQueryType;
  pageContext: PageContextType;
};

const Advice = ({ data, pageContext }: AdviceProps) => {
  const {
    title,
    shortDescription,
    image,
    content,
    relatedProducts,
    productCategories,
    document,
    updatedAt,
  } = data.contentfulAdvice;

  return (
    <Layout
      pageTitle={title as string}
      pageDescription={extractShortDescription(shortDescription as any)}
      pageImage={image || undefined}
      pageLocale={pageContext.node_locale}
      pageType="advice"
      siteUrl={data.site.siteMetadata.siteUrl}
      pageSlug={data.contentfulAdvice.slug ? data.contentfulAdvice.slug : ''}
      contentfulSettings={data.contentfulSettings}
      pageSettings={data.contentfulAdvice.metaTags}
      cookieBannerTitle={data.contentfulCookieBanner?.title}
      cookieBannerDescription={data.contentfulCookieBanner?.description}
      showFooterSubscribeForm={data.contentfulSettings.footerShowSubscribeForm || false}
      subscribeFormHeading={data.contentfulSettings.footerSubscribeFormHeading?.footerSubscribeFormHeading}
      flexmailId={data.contentfulSettings.flexmailId}
      updatedAt={updatedAt}
      ogPageType="article"
      translatedSlugs={data.translatedSlugs.nodes}
    >
      <AdvicePage
        title={title as string}
        shortDescription={extractShortDescription(shortDescription as any)}
        contentsSection={{
          data: content ? richTextRawToJson(content.richText, pageContext.node_locale).json : null,
        }}
        image={image}
        featuredProducts={relatedProducts ? transformProductsServicesSection(relatedProducts) : null}
        categoriesSection={productCategories ? transformProductCategoriesSection(productCategories) : null}
        documentDownload={extractFileInforamtion(document)}
      />
    </Layout>
  );
};

export default Advice;

export const pageQuery = graphql`
  query($id: String!, $node_locale: String!, $brand_tag: String!, $contentful_id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulCookieBanner(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      title
      description {
        raw
      }
    }
    translatedSlugs: allContentfulAdvice(filter: { contentful_id: { eq: $contentful_id } }) {
      nodes {
        contentful_id
        slug
        node_locale
      }
    }
    contentfulAdvice(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      title
      slug
      updatedAt(formatString: "YYYYMMDDHHmmss")
      seo {
        id
        description
        legacyDescription {
          legacyDescription
        }
        keywords
        no_index
        no_follow
        canonicalUrlpath
        title
      }

      # TODO migrate to seo
      metaTags {
        id
        description
        nofollow
        title
        keywords
        canonical
        noindex
      }
      document {
        title
        asset {
          description
          file {
            contentType
            url
            details {
              size
            }
            fileName
          }
        }
      }
      image {
        title
        alt: altText
        asset {
          # fluid(resizingBehavior: FILL, quality: 85) {
          #   ...QueryImageFluid
          # }
          gatsbyImageData(layout: FULL_WIDTH, resizingBehavior: FILL, quality: 85)
        }
        socialImage: asset {
          # fixed(width: 1200, height: 1200, resizingBehavior: CROP, quality: 100) {
          #   ...QueryImageFixed
          # }
          gatsbyImageData(layout: FIXED, width: 1200, height: 630, quality: 100)
        }
      }
      author
      shortDescription {
        text: shortDescription
      }
      content {
        __typename
        ... on ContentfulRichTextSection {
          contentful_id
          sys {
            contentType {
              sys {
                id
              }
            }
          }
          alignment
          richText {
            raw
            references {
              __typename
              ... on ContentfulAdvice {
                contentful_id
                slug
              }

              ... on ContentfulBasicPage {
                contentful_id
                slug
              }

              ... on ContentfulEvent {
                contentful_id
                slug
              }

              ... on ContentfulNews {
                contentful_id
                slug
              }

              ... on ContentfulProduct {
                contentful_id
                slug
              }

              ... on ContentfulAsset {
                contentful_id
                file {
                  url
                }
              }

              ... on ContentfulAttachment {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                asset {
                  file {
                    url
                    details {
                      size
                    }
                    contentType
                  }
                }
              }

              ... on ContentfulBlockquote {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                quote {
                  text: quote
                }
                author
                authorInfo
                image {
                  asset {
                    file {
                      url
                    }
                    gatsbyImageData
                  }
                }
              }

              ... on ContentfulImage {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                alt: altText
                asset {
                  file {
                    url
                    details {
                      image {
                        width
                        height
                      }
                    }
                  }
                  gatsbyImageData
                }
              }

              ... on ContentfulMediaBlock {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                shortDescription {
                  text: shortDescription
                }
                caption {
                  text: caption
                }
                orientation
                media {
                  ... on ContentfulImage {
                    __typename
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    title
                    alt: altText
                    asset {
                      file {
                        url
                      }
                      portraitImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.74)
                      squareImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
                      landscapeImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.77)
                    }
                  }

                  ... on ContentfulVideo {
                    contentful_id
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                    videoUrl
                  }
                }
              }

              ... on ContentfulShortBanner {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                title
                image {
                  asset {
                    file {
                      url
                      details {
                        image {
                          width
                          height
                        }
                      }
                    }
                  }
                }
                cta {
                  title
                  internalEntry {
                    __typename
                    ... on ContentfulAdvice {
                      contentful_id
                      slug
                    }

                    ... on ContentfulBasicPage {
                      contentful_id
                      slug
                    }

                    ... on ContentfulEvent {
                      contentful_id
                      slug
                    }

                    ... on ContentfulNews {
                      contentful_id
                      slug
                    }

                    ... on ContentfulProduct {
                      contentful_id
                      slug
                    }
                  }
                  externalUrl
                }
              }

              ... on ContentfulTextSectionHighlight {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                richTextContents {
                  raw
                  references {
                    __typename
                    ... on ContentfulAdvice {
                      contentful_id
                      slug
                    }

                    ... on ContentfulBasicPage {
                      contentful_id
                      slug
                    }

                    ... on ContentfulEvent {
                      contentful_id
                      slug
                    }

                    ... on ContentfulNews {
                      contentful_id
                      slug
                    }

                    ... on ContentfulProduct {
                      contentful_id
                      slug
                    }

                    ... on ContentfulAsset {
                      contentful_id
                      file {
                        url
                      }
                    }
                  }
                }
              }

              ... on ContentfulVideo {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                videoUrl
              }

              ... on ContentfulRichTextSection {
                contentful_id
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
                alignment
                richText {
                  raw
                  references {
                    __typename
                    ... on ContentfulAdvice {
                      contentful_id
                      slug
                    }

                    ... on ContentfulBasicPage {
                      contentful_id
                      slug
                    }

                    ... on ContentfulEvent {
                      contentful_id
                      slug
                    }

                    ... on ContentfulNews {
                      contentful_id
                      slug
                    }

                    ... on ContentfulProduct {
                      contentful_id
                      slug
                    }

                    ... on ContentfulAsset {
                      contentful_id
                      file {
                        url
                      }
                    }

                    ... on ContentfulAttachment {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      asset {
                        file {
                          url
                          details {
                            size
                          }
                          contentType
                        }
                      }
                    }

                    ... on ContentfulBlockquote {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      quote {
                        text: quote
                      }
                      author
                      authorInfo
                      image {
                        asset {
                          file {
                            url
                          }
                          gatsbyImageData
                        }
                      }
                    }

                    ... on ContentfulImage {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      alt: altText
                      asset {
                        file {
                          url
                          details {
                            image {
                              width
                              height
                            }
                          }
                        }
                        gatsbyImageData
                      }
                    }

                    ... on ContentfulMediaBlock {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      shortDescription {
                        text: shortDescription
                      }
                      caption {
                        text: caption
                      }
                      orientation
                      media {
                        ... on ContentfulImage {
                          __typename
                          contentful_id
                          sys {
                            contentType {
                              sys {
                                id
                              }
                            }
                          }
                          title
                          alt: altText
                          asset {
                            file {
                              url
                            }
                            portraitImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.74)
                            squareImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
                            landscapeImage: gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1.77)
                          }
                        }

                        ... on ContentfulVideo {
                          contentful_id
                          sys {
                            contentType {
                              sys {
                                id
                              }
                            }
                          }
                          videoUrl
                        }
                      }
                    }

                    ... on ContentfulShortBanner {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      title
                      image {
                        title
                        alt: altText
                        asset {
                          file {
                            url
                            details {
                              image {
                                width
                                height
                              }
                            }
                          }
                          gatsbyImageData
                        }
                      }
                      cta {
                        title
                        internalEntry {
                          __typename
                          ... on ContentfulAdvice {
                            contentful_id
                            slug
                          }

                          ... on ContentfulBasicPage {
                            contentful_id
                            slug
                          }

                          ... on ContentfulEvent {
                            contentful_id
                            slug
                          }

                          ... on ContentfulNews {
                            contentful_id
                            slug
                          }

                          ... on ContentfulProduct {
                            contentful_id
                            slug
                          }
                        }
                        externalUrl
                      }
                    }

                    ... on ContentfulTable {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      table {
                        tableData
                      }
                      fixFirstColumn
                    }

                    ... on ContentfulTextSectionHighlight {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      richTextContents {
                        raw
                        references {
                          __typename
                          ... on ContentfulAdvice {
                            contentful_id
                            slug
                          }

                          ... on ContentfulBasicPage {
                            contentful_id
                            slug
                          }

                          ... on ContentfulEvent {
                            contentful_id
                            slug
                          }

                          ... on ContentfulNews {
                            contentful_id
                            slug
                          }

                          ... on ContentfulProduct {
                            contentful_id
                            slug
                          }

                          ... on ContentfulAsset {
                            contentful_id
                            file {
                              url
                            }
                          }
                        }
                      }
                    }

                    ... on ContentfulVideo {
                      contentful_id
                      sys {
                        contentType {
                          sys {
                            id
                          }
                        }
                      }
                      videoUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
      relatedProducts {
        title
        shortDescription {
          text: shortDescription
        }
        products {
          slug
          title
          shortDescription {
            text: shortDescription
          }
          price
          image {
            title
            alt: altText
            asset {
              # fluid {
              #   ...QueryImageFluid
              # }
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          id
        }
        cta {
          externalUrl
          internalEntry {
            ...FragmentNavigationLink
          }
          title
        }
      }
      productCategories {
        ...QueryProductCategoriesSection
      }
    }

    contentfulAdvicesSection(metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }) {
      title
      shortDescription {
        text: shortDescription
      }
      advices {
        __typename
        ... on ContentfulAdvice {
          slug
          title
          shortDescription {
            text: shortDescription
          }
          image {
            asset {
              # fixed(height: 670, width: 670, quality: 80) {
              #   ...QueryImageFixed
              # }
              gatsbyImageData(layout: FIXED, height: 670, width: 670, quality: 80)
            }
          }
        }
      }
    }

    contentfulSettings(
      node_locale: { eq: $node_locale }
      metadata: { tags: { elemMatch: { contentful_id: { eq: $brand_tag } } } }
    ) {
      ...QuerySiteSettings
    }
  }
`;
