import extractCta from './Cta';
import { ContentfulProductsServicesSection, ContentfulProduct, Maybe } from '../generated/gatsby.types';
import extractShortDescription from './ShortDescription';

const extractProducts = (products: Maybe<Array<Maybe<ContentfulProduct>>>) => {
  if (!products) {
    return [];
  }
  return products
    .map((item: Maybe<ContentfulProduct>) => {
      if (!item) {
        return null;
      }
      return {
        id: item.id,
        title: item.title,
        image: item.image,
        shortDescription: extractShortDescription(item),
        link: item.slug,
      };
    })
    .filter(Boolean)
    .slice(0, 3);
};

const trasnformProductsServicesSection = (item: ContentfulProductsServicesSection) => {
  return {
    __typename: 'ProductsServicesSection',
    key: item.id,
    title: item.title,
    shortDescription: extractShortDescription(item),
    cta: extractCta(item),
    products: item.products ? extractProducts(item.products) : [],
  };
};

export default trasnformProductsServicesSection;
