/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ContentfulProductCategoriesSection,
  Maybe,
  ContentfulProductCategory,
  ContentfulFixed,
  ContentfulAsset,
  ContentfulImage,
  Scalars,
} from '../generated/gatsby.types';
import extractShortDescription from './ShortDescription';
import extractCta from './Cta';

export const extractCategories = (categories: Maybe<Array<Maybe<ContentfulProductCategory>>>, cardLink: any) => {
  if (!categories) {
    return [];
  }
  return categories
    ?.map((item: Maybe<ContentfulProductCategory>) => {
      if (!item) {
        return null;
      }

      return {
        id: item.id,
        title: item?.title,
        // image: item.image?.asset?.fixed?.src,
        image: item.image,
        link:
          item?.cta?.internalEntry?.slug ||
          item?.cta?.externalUrl ||
          cardLink?.internalEntry?.slug ||
          cardLink?.externalUrl ||
          null,
        shortDescription: extractShortDescription(item),
      };
    })
    .filter(Boolean);
};

type ProductCategoriesSection = ContentfulProductCategoriesSection & {
  image?: Maybe<ContentfulImage> & {
    asset?: Maybe<ContentfulAsset> & {
      fixed1?: Maybe<ContentfulFixed>;
      fixed2?: Maybe<ContentfulFixed>;
      gatsbyImageData1?: Maybe<Scalars['JSON']>;
      gatsbyImageData2?: Maybe<Scalars['JSON']>;
    };
  };
};

const transformProductCategoriesSection = (item: ProductCategoriesSection) => {
  const data = {
    __typename: 'ProductCategoriesSection',
    key: item.id,
    title: item.title,
    shortDescription: extractShortDescription(item),
    categories:
      item?.categories && Array.isArray(item.categories) ? extractCategories(item.categories, item.cardlink) : [],
    cta: extractCta(item),
    variant: item.variant ? item.variant : 'primary',
    image: item.image,
  };

  if (item?.variant === 'tertiary') {
    // const image1 = item?.image?.asset?.fixed1 || null;
    // const image2 = item?.image?.asset?.fixed2 || null;
    const image1 = item?.image?.asset?.gatsbyImageData1 || null;
    const image2 = item?.image?.asset?.gatsbyImageData2 || null;
    const imageSources = {
      sources: [
        null,
        // Add alt explicitely to use with withArtDirection function
        { ...image1, alt: item.image?.altText || item.image?.title || '' },
        { ...image2, alt: item.image?.altText || item.image?.title || '' },
      ],
      alt: item.image?.altText || item.title,
    };

    // @ts-ignore
    data.image = imageSources;
  }

  return data;
};

export default transformProductCategoriesSection;
